import React, { useEffect } from "react";
import pass from "./images/pass.svg";
import EANewLogo from "./images/EA-Logo-New.svg";
import { useOktaAuth } from "@okta/okta-react";

import "./SessionEnd.css";

const SessionEndedComponent = () => {
    useEffect(() => {}, []);
    return (
        <div className="space-x-2">
            <header className="px-4 bg-blue px-5-py-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between">
                        <div className="w-full"></div>
                    </div>
                </div>
            </header>
            <div className="flex" style={{ height: "60px" }}>
                <div
                    className="w-full ea-new-logo"
                    style={{
                        position: "relative",
                    }}
                >
                    <img src={EANewLogo} alt="EA logo"></img>
                </div>
            </div>
            <div className="bg-shape">
                <div
                    className="block m-auto py-32"
                    style={{
                        padding: "15rem 0",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    <div className="flex justify-center text-center sm:items-center items-baseline">
                        <div className="">
                            <div className="m-auto">
                                <div className="max-w-3xl text-center">
                                    <h1 className="text-4xl font-title text-[42px] text-[#303030] font-bold">
                                        You've been signed out
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SessionEndedComponent;
